.checkoutCarousel {
  width: 100%;
  padding: 48px 0;

  .cta {
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .info {
    position: absolute;
    width: 400px;
    height: 400px;
    left: -200px;
    top: -200px;
    cursor: pointer;
    overflow: visible;

    .label {
      position: absolute;
      left: 0;
      bottom: 45px;
      text-align: left;
      line-height: normal;
      transition: all ease-in-out 0.3s;
      transition-delay: 100ms;
      opacity: 0.5;
      transform: translate(60px, 20px);

      h3 {
        font-size: 22px;
        font-weight: 600;
        color: #3fcae8;
        margin: 0;
      }

      p {
        margin: 0;
        margin-top: 4px;
      }
    }

    .line {
      position: absolute;
      right: 50%;
      top: 50%;
      height: 145px;
      left: 0;
      margin-top: 23px;
      opacity: 0;
      transition: opacity 250ms ease-in-out;
      transition-delay: 200ms;
      border-radius: 0 0 15px 0;
      border-right: 2px solid #3fcae8;
      border-bottom: 2px solid #3fcae8;

      &:after {
        content: '';
        position: absolute;
        left: -5px;
        bottom: -5px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #3fcae8;
      }
    }

    .link {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      bottom: -10px;
      left: 0;
    }

    &.active {
      .label {
        opacity: 1;
        transform: none;
      }

      .line {
        opacity: 1;
      }

      .link {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
}

.pulseIcon {
  &.visible {
    opacity: 1;

    > span {
      animation: pulse 4s infinite;
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #3fcae8;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;

  &:after {
    content: '';
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background: #3fcae8;
    border-radius: 100%;
  }

  > span {
    box-sizing: border-box;
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    border: 1px solid #3fcae8;
    will-change: transform, opacity;

    &:nth-child(2) {
      animation-delay: 750ms;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  25% {
    transform: scale(1);
    opacity: 1;
  }

  75% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
