@use '../../layout/controls/button/Button.module.scss';
@use './ShipsListing.module.scss';
@use '../../../styles/style.scss';

.shopifyContainer {
  min-height: 75vh;

  :global(.shopify-buy__collection-products) {
    @extend .container-grid;
  }

  :global(.shopify-buy__product) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #3fcae8;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), #3fcae8;

    display: grid;
    grid-template-columns: 1fr 100px;
    grid-template-areas:
      'logo logo'
      'title price'
      'button button';
    height: 100%;
  }

  :global(.shopify-buy__product-img-wrapper) {
    grid-area: logo;

    img {
      height: 200px;
      margin: 0 auto;
      margin-bottom: 32px;
    }
  }

  :global(.shopify-buy__product__title) {
    font-size: 18px !important;
    text-align: left;
    font-weight: 600;
    grid-area: title;
    color: #3fcae8;
    justify-content: flex-end;
    margin: 0;
    padding-right: 32px;
  }

  :global(.shopify-buy__product__price) {
    font-weight: 600;
    grid-area: price;
    line-height: normal;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;

    :global(.visuallyhidden) {
      display: none;
    }
  }

  :global(.shopify-buy__btn-wrapper) {
    grid-area: button;
    margin-top: 32px;
  }

  :global(.shopify-buy__btn) {
    @extend .button;
    @extend .rectangle;
    @extend .medium;

    &:global(.shopify-buy__collection-pagination-button) {
      display: none;
    }
  }
}
