.content {
  padding: 32px 42px;
  border-radius: 8px;
  border: 1px solid #36a8c2;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), #36a8c2;
  text-align: left;
  color: #a4a4a4;

  &.primary {
    border: 1px solid #3fcae8;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), #3fcae8;
  }

  .topRow {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .back {
      cursor: pointer;
    }

    .favoriteIcon {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    h2 {
      text-align: left;
      color: #3fcae8;
      font-weight: 600;
      margin: 4px 0;
      max-width: 100%;
    }

    h4 {
      text-align: left;
      margin: 4px 0;
      max-width: 100%;
    }

    .titles {
      flex-grow: 1;
      text-align: left;
    }

    p {
      margin: 0 0;
      max-width: 100%;
      text-align: right;
      font-size: 24px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  p {
    margin: 8px 0;
    max-width: 100%;
  }

  .description {
    padding: 0;
    color: #c2c2c2;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
    flex-grow: 1;
    margin: 0;
    margin-top: 24px;
    max-width: 100%;
  }

  .related {
    margin-top: 12px;
    a {
      text-decoration: none !important;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 8px;
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }

    img {
      width: 85px;
      height: 85px;
      display: block;
    }

    span {
      display: block;
      text-align: center;
      color: #3fcae8;
      font-weight: 600;
      padding-top: 12px;
    }
  }

  h1 {
    width: 100%;
    max-width: 100%;
    text-align: left;
    padding: 0;
    color: #3fcae8;
    font-size: 38px;
    font-weight: 600;
    margin: 0;
    display: flex;
    flex-direction: row;
    span {
      opacity: 0.2;
    }
  
    button {
      width: 50px;
      margin-left: auto;
      background-color: transparent;
      color: #36acc5;
      clip-path: none;
      border: 1.4px solid #36acc5;
      border-radius: 4px;
      cursor: pointer;
  
      &:before {
        display: none;
      }
  
      &:hover {
        background-color: #22788a;
        color: #fcfcfc;
      }
    }
  }

  h4 {
    text-align: left;
    margin: 4px 0;
    max-width: 100%;
    font-weight: 600;
    color: #3fcae8;
    opacity: 0.3;
  }

  hr {
    margin: 0;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #22788a;
  }
  
  .innerBox {
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), #3fcae8;
    padding: 32px;
  }

  .columns {
    width: 100%;
    display: flex;
    gap: 12px;
    @media (min-width: 930px) {
      flex-direction: row;
      align-content: space-between;
    }
    @media (max-width: 930px) {
      flex-direction: column;
      align-content: flex-start;
    }
  }

  .columnsFixed {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
  }
}
