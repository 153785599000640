.overlay {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  position: absolute;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);

  .overlayContainer {
    position: relative;
    display: inline-block;
    line-height: 0;
    margin-top: 12px;
    margin-left: 8px;

    .frame {
      display: block;
      margin: auto;
      position: relative;
      pointer-events: none;
    }

    .clippath {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .overlayContent {
      display: flex;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
      align-items: flex-start;
      justify-content: center;
      scroll-behavior: smooth;
      padding: 105px 0;
      padding-right: 10px;
      background: rgba(0, 0, 0, 0);

      &.highEnd {
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      & {
        scrollbar-width: none;
      }
    }

    .header {
      position: relative;
      width: 100%;
      text-align: center;
      line-height: normal;
      height: 30px;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-family:
        IBM Plex Mono,
        monospace;

      .logoContainer {
        position: absolute;
        min-width: 80px;
        left: 5px;
        display: flex;
        align-items: start;
        justify-content: start;
      }

      .logo {
        margin-top: 4px;
        margin-left: 4px;
        height: 18px;
        width: auto;
      }

      .icon {
        margin-left: 0.6rem;
      }

      .headerActions {
        position: absolute;
        right: 10px;
        top: 3px;
        font-family: inherit;
        font-size: 13px;
        letter-spacing: 12%;
        font-weight: 600;
        opacity: 1;
        display: flex;
        gap: 0px;

        .action {
          cursor: pointer;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: inherit;
          padding-right: 4px;
          opacity: 1;
          position: relative;
          width: 57px;
          height: 28px;
          background: #0D282E;
          border: 1.5px solid #428B9B;
          border-radius: 10px;
          color: #428B9B;

          &:hover {
            color: #3fcae8;
            filter: drop-shadow(0 0 6px rgba(63, 202, 232, 1));
          }
        }
        .action:first-of-type {
          width: 66px;
          border: none;
          background: none;
          
          .background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            svg {
              height: 13px !important;
              z-index: 1;
              padding-left: 8px;
            }
          }
        }
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: inherit;
        font-size: 13px;
        letter-spacing: 12%;
        font-weight: 400;

        .menuToggle {
          background: none;
          border: none;
          cursor: pointer;
          font-family: inherit;
          font-size: 13px;
          letter-spacing: 12%;
          font-weight: 600;
          padding: 10px 12px;
          letter-spacing: 1.9px;
          opacity: 1;
          color: #fafafa;
        }
      }

      .menuLink {
        text-decoration: none;
        color: inherit;
        position: relative;
        padding: 10px 12px;
        letter-spacing: 1.9px;
        opacity: 0.64;

        .underline {
          pointer-events: none;
          transition: opacity 0.3s ease-in-out;
          opacity: 0;
          width: 100%;
          height: 4px;
          background: #3fcae8;
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: -3px;
          border-radius: 5px 5px 0 0;
        }

        .underlineBlur {
          overflow: hidden;
          width: 200%;
          height: 20px;
          position: absolute;
          left: 0;
          top: 100%;
          margin-top: -3px;
          transform: translate(-25%, 0);

          &::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 12px;
            left: 25%;
            background: #3fcae8;
            border-radius: 0 0 5px 5px;
            filter: blur(6px);
          }
        }

        &:hover {
          font-weight: 600;
          opacity: 1;
        }

        &:hover .underline {
          opacity: 1;
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 12px;
      width: 100%;
      line-height: normal;
      font-family:
        IBM Plex Mono,
        monospace;
      font-size: 13px;
      letter-spacing: 12%;
      font-weight: 600;
    }

    .backToTop {
      transition: opacity 0.5s;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }
}
