.infoTable {
  display: grid;
  margin: 0;
  margin-top: 12px;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;
  list-style: none;
  align-items: center;
  justify-items: center;
  color: #fff;
  font-size: 14px;
  line-height: normal;
  padding-left: 0;

  strong {
    margin-right: 16px;
    color: #a4a4a4;
    text-align: right;
  }

  li {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
