.footer {
  &:before {
    content: '';
    background-image: url('https://statics.astronation.world/i/leads/footer-bg.png');
    background-size: cover;
    background-position: bottom;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    aspect-ratio: 1.6;
    pointer-events: none;
    z-index: -1;
  }

  position: relative;
  padding-bottom: 295px;
  padding-top: 32px;
  margin-bottom: -105px;

  display: grid;
  padding-left: 64px;
  padding-right: 64px;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 48px;
  grid-row-gap: 48px;

  .logo {
    width: 100%;
  }

  img {
    max-width: 420px;
    width: 100%;
  }

  .links {
    display: flex;
    line-height: 32px;
    color: white;

    text-align: left;
    align-items: center;
    justify-content: center;

    a {
      display: block;
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    flex-direction: row;

    a {
      width: 48px;
    }
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);

    img {
      max-width: 300px;
    }

    .logo {
      grid-column: 1 / 3;
    }

    .links,
    .social {
      grid-row: 2;
    }
  }
}
