.preview {
  height: 240px;
  width: 100%;
  margin-bottom: 12px;
}

p.description {
  color: #c2c2c2;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: left;
  flex-grow: 1;
}

a {
  width: 100%;
}
