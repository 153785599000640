.exp-filter {
  filter: brightness(0) saturate(100%) invert(48%) sepia(79%) saturate(2476%) hue-rotate(190deg)
    brightness(89%) contrast(89%);
}

.bad-rep-filter {
  filter: brightness(0) saturate(100%) invert(19%) sepia(92%) saturate(6324%) hue-rotate(347deg)
    brightness(60%) contrast(90%);
}

.good-rep-filter {
  filter: brightness(0) saturate(100%) invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(78%) contrast(79%);
}
