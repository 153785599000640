@use 'clipPaths';

button.button {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  z-index: 10;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  font-family:
    IBM Plex Mono,
    monospace;
  font-weight: 600;
  letter-spacing: -1%;
  background-color: transparent;
  width: 100%;
  color: #fafafa;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 50;
    background-color: #3fcae8;
    clip-path: clipPaths.$borderClipPath;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    clip-path: clipPaths.$shapeClipPath;
    transition: all 0.2s ease-in-out;
  }

  &.primary:hover {
    animation: none;
    filter: drop-shadow(0 0 30px rgba(63, 202, 232, 1));
  }

  @keyframes pulse {
    0% {
      filter: drop-shadow(0 0 30px rgba(63, 202, 232, 1));
    }
    50% {
      filter: drop-shadow(0 0 15px rgba(63, 202, 232, 0.64));
    }
    100% {
      filter: drop-shadow(0 0 30px rgba(63, 202, 232, 1));
    }
  }

  &.primary {
    animation: pulse 2s ease-in-out infinite;
    color: #3fcae8;
    margin: 24px 0;

    &:before {
      background-color: #3fcae8;
    }

    &:after {
      background-color: #0d292e;
    }

    &:hover {
      &:after {
        background-color: #22788a;
      }
      color: #fcfcfc;
    }
  }

  &.secondary {
    color: #36a8c2;

    &:before {
      background-color: #36a8c2;
    }

    &:after {
      background-color: transparent;
    }

    &:hover {
      &:after {
        background-color: rgb(32, 109, 124);
      }
      color: #fafafa;
    }
  }

  &.rectangle {
    background-color: transparent;
    color: #36acc5;
    clip-path: none;
    border: 1px solid #36acc5;
    border-radius: 4px;

    &:before {
      display: none;
    }

    &:hover {
      background-color: #22788a;
      color: #fcfcfc;
    }
  }

  &.ghost {
    color: #aaa;

    &:before {
      display: none;
    }

    &:hover {
      color: #3fcae8;
    }
  }

  &.medium {
    padding: 13px 24px;
  }

  &.small {
    padding: 10px 36px;
    font-size: 12px;
    line-height: 100%;
  }

  &.large {
    padding: 22px 28px;
    max-width: 468px;
    font-size: 16px;
  }
}
