.dotsContainer {
  position: absolute;

  top: calc(50% - 40px);
  width: 12px;
  height: 110px;

  &.left {
    left: -8px;
  }
  &.right {
    right: -3px;
  }
}

.dots {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.dot {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  cursor: pointer;
  border-radius: 50%;
  border: 1.4px solid rgba(63, 202, 232, 0.3);
  transition: all 0.3s ease;

  &.active {
    background: #174851;
    border: 1.4px solid #37afc9;
  }

  &:hover {
    background: #194f58;
  }
}

.marker {
  position: relative;
  height: 0;
  width: 100%;
}
