.pulseIcon {
  &.visible {
    opacity: 1;

    > span {
      animation: pulse 4s infinite;
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #3fcae8;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;

  &:after {
    content: '';
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background: #3fcae8;
    border-radius: 100%;
  }

  > span {
    box-sizing: border-box;
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    border: 1px solid #3fcae8;
    will-change: transform, opacity;

    &:nth-child(2) {
      animation-delay: 750ms;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  25% {
    transform: scale(1);
    opacity: 1;
  }

  75% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
