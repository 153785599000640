.login {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  h1 {
    font-size: 32px;
    margin-bottom: 48px;
    margin-left: 0;
  }

  .logo {
    align-self: flex-start;
    width: 91px;
  }

  section,
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: auto;
    width: 468px;
  }

  section {
    p {
      opacity: 0.64;
      line-height: normal;
      font-size: 12px;
      font-family: inherit;
      margin: 0;
      margin-bottom: 48px;
    }
  }
}
