.filters {
  display: flex;
  align-items: center;
  flex-direction: column;

  .list {
    margin: 0 auto;
    list-style: none;
    display: flex;
    gap: 4px;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
  }

  .item {
    button {
      line-height: normal;
      text-transform: capitalize;
      display: flex;
      height: 42px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      cursor: pointer;
      font-size: 15px;
      line-height: 20px;
      transition: all 0.3s ease;
      border: 1px solid #425e5f;
      background: #0d292e;
      color: #3fcae8;

      .count {
        padding: 0px 6px;
        border-radius: 4px;
        opacity: 1;
        font-weight: 500;
        font-size: 12px;
        background: rgba(236, 236, 237, 0.15);
        transition: all 0.1s ease;
      }
    }

    &.active button {
      color: #0d282e;
      border: 1px solid #3fcae8;
      background: #39b5d1;

      .count {
        color: #0d282e;
        background: rgba(30, 55, 61, 0.15);
      }
    }

    &:not(.active) button:hover {
      border-color: #3fcae8;
      background: #17474f;

      .count {
        color: #3fcae8;
      }
    }
  }
}
