.controls {
  top: 62px;
  left: 24px;
}

.cta {
  width: 100%;
  margin-top: 16px;
}

.payment {
  .purchased {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 24px;
  }

  .paypal {
    margin-bottom: 48px;
  }

  .crypto {
    a {
      text-decoration: none;
      display: block;
      width: 100%;
    }

    & > * + * {
      margin-top: 12px;
    }
  }
}
