.cvContainer {
  display: flex;
  gap: 85px;
  padding-left: 90px;
  padding-right: 115px;
  align-items: safe stretch;
  width: 100%;
  box-sizing: border-box;
  margin-top: 64px;

  @media screen and (max-width: 1300px) {
    display: block;
    height: auto;
  }

  .preview {
    position: relative;
    flex-grow: 1;
    overflow: visible;

    .previewWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 1300px) {
        position: relative;
      }
    }
  }

  .details {
    width: 468px;
    margin-top: 35px;
    margin-bottom: 35px;

    @media screen and (max-width: 1300px) {
      width: 100%;
    }

    p:first-child {
      margin-bottom: 8px;
      color: white;
      opacity: 0.82;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .buttons {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

.controls {
  top: 55px;
  left: 30px;
}
