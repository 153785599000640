.spinnerContainer {
  width: 100%;
  margin: 0 auto;
  height: 0;

  .spinner {
    width: 32px;
    height: 32px;
    border: 3px solid #36acc5;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
