.favoriteToggle {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #19333a;
  border: none;
  border-radius: 4px;
  color: #3fcae8;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(#19333a, 0.5);
  }
}
