.profileContent {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  line-height: normal;
  margin: 0 auto;

  .infoRow {
    width: 100%;
    text-align: left;
    padding-bottom: 24px;
  
    .label {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 4px;
    }
  
    .value {
      color: #3fcae8;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .avatar {
    width: 124px;
    height: 124px;
    border-radius: 50%;
    overflow: hidden;
    border: 1.5px solid #3fcae8;
    margin-left: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .stats {
    grid-row: 1 / 3;
    grid-column: 2;
  }

  .statsRows {
    width: 100%;
  }
  
  .statsRow {
    width: 100%;
  
    & + .statsRow {
      margin-top: 16px;
    }
  
    .label {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
  
  .details {
    width: 100%;
    display: flex;
    flex-direction: column;
  
    .label {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  
    .box {
      border: none;
      border-radius: 8px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), #3fcae8;
      padding: 32px;
      color: #fafafa;
      height: 100%;
    }
  }
}
