.container-list {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 96px;
  padding: 0 78px;

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
      gap: 0;
    }
  }
}
