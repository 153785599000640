.layout {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  position: absolute;
  text-align: center;
}
