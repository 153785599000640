.planetCarousel {
  width: 100%;
  overflow: hidden;
  padding-bottom: 60px;

  .overlay {
    position: absolute;
    width: 300px;
    height: 460px;
    left: -150px;
    top: -180px;
    cursor: pointer;
    border-radius: 16px;
    background: rgba(63, 202, 232, 0);
    transition: all 0.3s ease-in-out;
    padding: 12px 16px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;

    &:hover {
      background: rgba(63, 202, 232, 0.05);
    }

    &.active {
      background: rgba(63, 202, 232, 0.15);

      .info {
        opacity: 1;
        transform: translateY(0);

        .link {
          opacity: 1;
        }
      }
    }

    .info {
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      text-align: left;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      transform: translateY(50px);

      h3 {
        color: #3fcae8;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }

      p {
        color: #fff;
        font-family: 'IBM Plex Mono';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.064px;
        padding-right: 32px;
      }

      .link {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .canvasContainer > *:first-child,
  .canvasContainer canvas {
    position: relative;
    pointer-events: none;
    z-index: 1;
  }
}
