.previewContainer {
  min-height: 300px;
  flex-grow: 1;
  position: relative;
  width: 100%;
  pointer-events: none;
}

.preview {
  aspect-ratio: 1;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);

  @media screen and (max-width: 1100px) {
    position: relative;
    transform: none;
    top: 0;
    max-height: 300px;
  }
}

.info {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.buttons {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
