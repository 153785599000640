.statsBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 54px;
    height: 54px;
  }
  img.dimmed {
    filter: opacity(0.3);
  }
}
