
@media screen and (min-width: 930px) {
  .tabContainer.verticalLayout {
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    
    .switch {
      margin: 0;
      margin-left: 5%;
      flex-direction: column;
      align-content: flex-start;
    }

    .content {
      width: 100%;
      margin: 0;
      margin-right: 5%;
    }
  }
}

.tabContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 96px;

  .switch {
    margin: 0 auto;
    list-style: none;
    display: flex;
    padding: 6px;
    gap: 12px;
    flex-grow: 0;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #3fcae8;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), #3fcae8;
  }

  .tab {
    a {
      line-height: normal;
      text-transform: capitalize;
      display: flex;
      height: 32px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      transition: all 0.3s ease;
      width: 144px;
      border: none;
      background: transparent;
      text-decoration: none;
      color: #3fcae8;
    }

    &.active a {
      color: #0d282e;
      background: #3fcae8;
    }

    &:not(.active) a:hover {
      background: rgba(#3fcae8, 0.1);
    }
  }

  .content {
    width: 90%;
    margin-top: 64px;
  }
}
