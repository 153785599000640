.vpCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: url('#overlayClipId');

  &,
  & > * {
    pointer-events: none !important;
  }
}
