.input {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Mono', monospace;
  display: inline-flex;

  label {
    color: white;
    text-align: left;
    line-height: 100%;
    margin-bottom: 1rem;
  }

  input {
    height: 4rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 2px solid #425e5f;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0.5rem 1rem;
    color: white;
    min-width: 336px;
    width: 100%;
    font-size: 1rem;
    font-family:
      IBM Plex Mono,
      monospace;

    &::placeholder {
      opacity: 0.5;
      color: white;
      font-size: 1rem;
    }
    &:focus {
      border-color: #3fcae8;
      outline: none;
    }
  }

  .error {
    display: block;
    font-size: 0.875rem;
    color: #e8423f;
    margin-top: 8px;
    line-height: normal;
  }

  &.invalid input {
    border-color: #e8423f;
    &:focus {
      border-color: #e8423f;
    }
  }
}
