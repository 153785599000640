.progressContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > *:first-child {
    border-radius: 45px;
    background: linear-gradient(0deg, #13353d 0%, #13353d 100%), #3fcae8;
    height: 16px;
    padding: 2px;

    & > * {
      display: block;
      border-radius: 45px;
      background: #3fcae8;
      height: 100%;
    }
  }

  & > *:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}
