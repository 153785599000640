.rotationControls {
  position: absolute;
  z-index: 1;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      width: 54px;
      height: 54px;
      margin-bottom: 8px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid rgba(#425e5f, 0.5);
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.selected {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(#3fcae8, 0.5);
      }

      &:hover {
        background: rgba(255, 255, 255, 0.02);
      }
    }
  }
}
