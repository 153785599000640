$shapeClipPath: polygon(
  18.02px 2.61px,
  18.98px 1.8px,
  20.05px 1.14px,
  21.2px 0.67px,
  22.42px 0.37px,
  23.68px 0.28px,
  calc(100% - 8.68px) 0.28px,
  calc(100% - 6.16px) 0.68px,
  calc(100% - 3.96px) 1.82px,
  calc(100% - 2.23px) 3.55px,
  calc(100% - 1.09px) 5.75px,
  calc(100% - 0.68px) 8.28px,
  calc(100% - 0.68px) calc(100% - 24.04px),
  calc(100% - 0.78px) calc(100% - 22.78px),
  calc(100% - 1.08px) calc(100% - 21.57px),
  calc(100% - 1.55px) calc(100% - 20.41px),
  calc(100% - 2.2px) calc(100% - 19.35px),
  calc(100% - 3.02px) calc(100% - 18.39px),
  calc(100% - 18.28px) calc(100% - 3.08px),
  calc(100% - 19.24px) calc(100% - 2.25px),
  calc(100% - 20.31px) calc(100% - 1.6px),
  calc(100% - 21.47px) calc(100% - 1.12px),
  calc(100% - 22.69px) calc(100% - 0.83px),
  calc(100% - 23.95px) calc(100% - 0.73px),
  8.32px calc(100% - 0.73px),
  5.79px calc(100% - 1.13px),
  3.59px calc(100% - 2.27px),
  1.86px calc(100% - 4px),
  0.73px calc(100% - 6.2px),
  0.32px calc(100% - 8.73px),
  0.32px 23.61px,
  0.42px 22.35px,
  0.71px 21.13px,
  1.19px 19.98px,
  1.84px 18.91px,
  2.67px 17.95px,
  18.02px 2.61px
);

$borderClipPath: polygon(
  18.02px 2.61px,
  18.98px 1.79px,
  20.04px 1.14px,
  21.2px 0.66px,
  22.41px 0.37px,
  23.67px 0.275px,
  calc(100% - 8.68px) 0.27px,
  calc(100% - 6.15px) 0.68px,
  calc(100% - 3.96px) 1.81px,
  calc(100% - 2.22px) 3.55px,
  calc(100% - 1.09px) 5.746488px,
  calc(100% - 0.68px) 8.27px,
  calc(100% - 0.68px) calc(100% - 24.03px),
  calc(100% - 0.78px) calc(100% - 22.77px),
  calc(100% - 1.07px) calc(100% - 21.56px),
  calc(100% - 1.55px) calc(100% - 20.41px),
  calc(100% - 2.2px) calc(100% - 19.34px),
  calc(100% - 3.01px) calc(100% - 18.38px),
  calc(100% - 18.28px) calc(100% - 3.07px),
  calc(100% - 19.24px) calc(100% - 2.25px),
  calc(100% - 20.31px) calc(100% - 1.59px),
  calc(100% - 21.46px) calc(100% - 1.11px),
  calc(100% - 22.68px) calc(100% - 0.82px),
  calc(100% - 23.94px) calc(100% - 0.72px),
  8.31px calc(100% - 0.72px),
  5.78px calc(100% - 1.13px),
  3.59px calc(100% - 2.26px),
  1.86px calc(100% - 4px),
  0.72px calc(100% - 6.19px),
  0.32px calc(100% - 8.72px),
  0.32px 23.61px,
  0.41px 22.34px,
  0.71px 21.13px,
  1.19px 19.97px,
  1.84px 18.9px,
  2.66px 17.94px,
  18.02px 2.61px,
  18.73px 3.32px,
  3.37px 18.65px,
  2.65px 19.49px,
  2.08px 20.43px,
  1.66px 21.44px,
  1.4px 22.5px,
  1.32px 23.61px,
  1.32px calc(100% - 8.72px),
  1.67px calc(100% - 6.51px),
  2.66px calc(100% - 4.59px),
  4.18px calc(100% - 3.07px),
  6.1px calc(100% - 2.08px),
  8.31px calc(100% - 1.72px),
  calc(100% - 23.94px) calc(100% - 1.72px),
  calc(100% - 22.84px) calc(100% - 1.81px),
  calc(100% - 21.77px) calc(100% - 2.07px),
  calc(100% - 20.76px) calc(100% - 2.48px),
  calc(100% - 19.83px) calc(100% - 3.06px),
  calc(100% - 18.99px) calc(100% - 3.78px),
  calc(100% - 3.72px) calc(100% - 19.09px),
  calc(100% - 3.01px) calc(100% - 19.93px),
  calc(100% - 2.44px) calc(100% - 20.86px),
  calc(100% - 2.02px) calc(100% - 21.87px),
  calc(100% - 1.77px) calc(100% - 22.93px),
  calc(100% - 1.68px) calc(100% - 24.03px),
  calc(100% - 1.68px) 8.27px,
  calc(100% - 2.04px) 6.06px,
  calc(100% - 3.03px) 4.14px,
  calc(100% - 4.54px) 2.62px,
  calc(100% - 6.47px) 1.63px,
  calc(100% - 8.68px) 1.27px,
  23.67px 1.27px,
  22.57px 1.36px,
  21.51px 1.61px,
  20.5px 2.03px,
  19.56px 2.6px,
  18.72px 3.32px
);
