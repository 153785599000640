.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
  padding-top: 12px;
  overflow: hidden;
  outline: none;
  position: absolute;
  text-align: center;
  display: inline-block;
  pointer-events: none;

    .header {
      position: relative;
      width: 100%;
      text-align: center;
      line-height: normal;
      height: 30px;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-family:
        IBM Plex Mono,
        monospace;

      .logoContainer {
        position: absolute;
        min-width: 80px;
        left: 5px;
        display: flex;
        align-items: start;
        justify-content: start;
        pointer-events: auto;
      }

      .logo {
        margin-top: 4px;
        margin-left: 4px;
        height: 18px;
        width: auto;
      }

      .icon {
        margin-left: 0.6rem;
      }

      .headerActions {
        position: absolute;
        right: 10px;
        top: 3px;
        font-family: inherit;
        font-size: 13px;
        letter-spacing: 12%;
        font-weight: 600;
        opacity: 1;
        display: flex;
        gap: 0px;
        pointer-events: auto;

        .action {
          cursor: pointer;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: inherit;
          padding-right: 4px;
          margin-right: 4px;
          opacity: 1;
          position: relative;
          width: 57px;
          height: 28px;
          background: #0D282E;
          border: 1.5px solid #428B9B;
          border-radius: 10px;
          color: #428B9B;

          &:hover {
            color: #3fcae8;
            filter: drop-shadow(0 0 6px rgba(63, 202, 232, 1));
          }
        }
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: inherit;
        font-size: 13px;
        letter-spacing: 12%;
        font-weight: 400;
        pointer-events: auto;

        .menuToggle {
          background: none;
          border: none;
          cursor: pointer;
          font-family: inherit;
          font-size: 13px;
          letter-spacing: 12%;
          font-weight: 600;
          padding: 10px 12px;
          letter-spacing: 1.9px;
          opacity: 1;
          color: #fafafa;
        }
      }

      .menuLink {
        text-decoration: none;
        color: inherit;
        position: relative;
        padding: 10px 12px;
        letter-spacing: 1.9px;
        opacity: 0.64;

        .underline {
          pointer-events: none;
          transition: opacity 0.3s ease-in-out;
          opacity: 0;
          width: 100%;
          height: 4px;
          background: #3fcae8;
          position: absolute;
          top: 100%;
          left: 0;
          margin-top: -3px;
          border-radius: 5px 5px 0 0;
        }

        .underlineBlur {
          overflow: hidden;
          width: 200%;
          height: 20px;
          position: absolute;
          left: 0;
          top: 100%;
          margin-top: -3px;
          transform: translate(-25%, 0);

          &::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 12px;
            left: 25%;
            background: #3fcae8;
            border-radius: 0 0 5px 5px;
            filter: blur(6px);
          }
        }

        &:hover {
          font-weight: 600;
          opacity: 1;
        }

        &:hover .underline {
          opacity: 1;
        }
      }
    }
}
