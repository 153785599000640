@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@import url('@fontsource/comfortaa/latin-300.css');
@import url('@fontsource/comfortaa/latin-500.css');
@import url('@fontsource/comfortaa/latin-700.css');

@import url('@fontsource/courier-prime/latin-400.css');

@import 'colors.scss';
@import 'container.scss';
@import 'container-text.scss';
@import 'container-grid.scss';
@import 'container-list.scss';

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
}

body {
  background: rgb(0, 10, 36);
  background: radial-gradient(circle, rgb(0, 6, 23) 0%, rgb(2, 16, 36) 35%, rgb(0, 30, 59) 100%);
  -webkit-font-smoothing: auto;
  -moz-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  color: #fafafa;
  font-family:
    IBM Plex Mono,
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
  font-weight: 400;
  color: #fafafa;

  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 42px;
}

h2 {
  margin-bottom: 16px;
  font-size: 36px;
}

p {
  letter-spacing: -1%;
  font-weight: 400;
  line-height: 1.7rem;

  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  strong {
    font-weight: 600;
    letter-spacing: 1%;
    color: #3fcae8;
  }
}

a {
  color: #fafafa;
  transition: color 0.2s;
  &:hover {
    color: #3fcae8;
  }
}

.video {
  width: 90%;
  max-width: 90%;
  aspect-ratio: 16 / 9;
  margin-top: 64px;
}

.logo {
  width: 96px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.hidden {
  flex: none !important;
  display: none !important;
  visibility: hidden !important;
}
