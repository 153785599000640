.select {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Mono', monospace;
  display: inline-flex;
  min-width: 100%;
  max-width: 100%;
  margin: 12px 0;

  label {
    color: white;
    text-align: left;
    line-height: 100%;
    margin-bottom: 1rem;
    opacity: 0.5;
  }

  select {
    height: 4rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 2px solid #425e5f;
    padding: 0.5rem 1rem;
    color: #fafafa;
    width: 100%;
    font-size: 1rem;
    font-family:
      IBM Plex Mono,
      monospace;

    &::placeholder {
      opacity: 0.5;
      color: #fafafa;
      font-size: 1rem;
    }
    &:focus {
      border-color: #3fcae8;
      outline: none;
    }

    background: url("data:image/svg+xml,<svg height='14px' width='14px' viewBox='0 0 16 16' fill='%233fcae8' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 1rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
        
    option {
      color: #040404;
    }
  }

  .error {
    display: block;
    font-size: 0.875rem;
    color: #e8423f;
    margin-top: 8px;
    line-height: normal;
  }

  &.invalid select {
    border-color: #e8423f;
    &:focus {
      border-color: #e8423f;
    }
  }
}
