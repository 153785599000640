.entityPreview {
  width: 100%;
  aspect-ratio: 1;
  position: relative;

  @media screen and (max-width: 1300px) {
    max-width: 500px;
    margin: auto;
    margin-bottom: 32px;
  }
}
