.ovContainer {
  position: relative;
  z-index: 2;
  margin-top: 64px;
  
  h2 {
    text-align: left;
    padding: 0;
    color: #3fcae8;
    font-size: 38px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    opacity: 0.2;
    margin-bottom: 0;
  }


  .overlay {
    position: absolute;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    width: 266px;
    height: 330px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.3s ease;

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 14px;
      text-align: left;
      line-height: normal;
      transition: all ease-in-out 0.3s;
      transition-delay: 100ms;
      opacity: 0.5;
      box-sizing: border-box;
      transform: translateY(40px);

      h3 {
        font-size: 22px;
        font-weight: 600;
        color: #3fcae8;
        margin: 0;
      }

      p {
        margin: 0;
        margin-top: 4px;
      }
    }

    .link {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
      width: 100%;
      margin: auto;
      margin-top: 14px;
    }

    &.active {
      background-color: rgba(63, 202, 232, 0.15);

      .content {
        opacity: 1;
        transform: none;
      }

      .link {
        pointer-events: all;
        opacity: 1;
      }
    }

    &.current {
      .link {
        pointer-events: none;
        cursor: default;
      }
    }

    &:not(.active):hover {
      background-color: rgba(63, 202, 232, 0.05);
    }
  }
}
